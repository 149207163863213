import * as Sentry from "@sentry/vue";
import { App } from "vue";
import { Router } from "vue-router";

export const initSentry = (app: App<Element>, router: Router) => {
  return Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    beforeSend: (event) => {
      if (!String(event).match(/401/g)) {
        return event;
      }
      return null;
    },
    integrations: [
      Sentry.browserTracingIntegration({router}),
      Sentry.replayIntegration(),
    ],

    ignoreErrors: [
      "Network request failed",
      "Importing a module script failed",
      "Failed to fetch",
      "NetworkError",
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
      "Failed to fetch dynamically imported module",
      "Failed to load module script",
      "Unable to preload CSS",
      "/.*@webkit-masked-url.*/",

      // Recommended ignores for javascript on Sentry's Docs. Ref: https://docs.sentry.io/platforms/javascript/configuration/filtering/
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
    ],
    // Recommended from sentry docs: Ref: https://docs.sentry.io/platforms/javascript/configuration/filtering/
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
  });
};
